import type { AppProps } from 'next/app';
import Router from 'next/router';
import { I18nProvider } from 'next-localization';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';
import client from 'src/apollo/provider';

import '../assets/scss/main.scss';
import '../assets/scss/font.css';
import 'assets/aha-pdc-featured-providers.css';
import '../lib/customScripts';
import { FilterProvider } from 'components/context/FilterContext';

NProgress.configure({ showSpinner: false, trickleSpeed: 100 });

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function App({ Component, pageProps }: AppProps): JSX.Element {
	const { dictionary, ...rest } = pageProps;
	return (
		// Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
		// Note Next.js does not (currently) provide anything for translation, only i18n routing.
		// If your app is not multilingual, next-localization and references to it can be removed.
		<ApolloProvider client={client}>
			<I18nProvider lngDict={dictionary} locale={pageProps.locale}>
				<FilterProvider>
					<Component {...rest} />
				</FilterProvider>
			</I18nProvider>
		</ApolloProvider>
	);
}

export default App;
