/**
 * Client Scripts File
 *
 * This file is designated for DOM manipulation scripts.
 * Please follow the guidelines below:
 *
 * 1. Define each script as a separate function.
 * 2. Create functions that are immediately executed, as shown in the example
 * 3. Keep your manipulations constrained to specific parts of the DOM to prevent unintended side effects.
 * 4. Test your scripts thoroughly to ensure they don't break other functionality.
 */

(function () {
	/**
  * Insert your functions inside the clojure following the example below:
  * 
	(function exampleScript() {
	  const element = document.getElementById('example');
	  console.log('Manipulating example', element);
	})();
  */

	/* Move secondary right column items to bottom of page at <1024px*/

	function moveElementToLastParent(elementToMove, targetParentSelector) {
		const targetParent = document.querySelector(targetParentSelector);
		if (targetParent) {
			targetParent.appendChild(elementToMove);
		}
	}

	function handleResponsiveLayout() {
		const screenWidth = window.innerWidth;
		const rightColumnInnerContainer = document.querySelector('.right-column > .jss-inner-container');

		if (screenWidth < 1024 && rightColumnInnerContainer) {
			moveElementToLastParent(rightColumnInnerContainer, '.left-column');
		}
	}

	function handleResize() {
		handleResponsiveLayout();
	}
	

	if (typeof window !== 'undefined') {
		window.addEventListener('DOMContentLoaded', handleResize);
		handleResponsiveLayout();
	}
	
})();


